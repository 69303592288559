import { IEnvironment } from './IEnvironment';

// inherent the interface to match the necessary scheme
export const environment: IEnvironment = {
  //defind environment properties
  production: false,
  //debugging prop
  debugging: true,
  // api to fetch for the form
  notifyApiBase: 'https://notify-api.staging.nside.io',
  // to fetch the list of school
  gatewayApibase: 'https://api.staging.nside.io',
  //api version
  apiVersion: 'v3.1',

};